<template>
  <div>
    <div class="row q-col-gutter-x-md q-col-gutter-y-md">
      <div class="col-12">
        <q-table
          title="Project Items"
          @row-click="showprojectItemDialogEvt"
          :rows="projectItems"
          :columns="projectItemColumns"
          row-key="quantityItem.description"
          dense
        >
          <template v-slot:top-right>
            <q-input borderless dense debounce="300" v-model="projectItemfilter" outlined placeholder="Search">
              <template v-slot:append>
                <q-icon name="search" />
              </template>
            </q-input>
          </template>
        </q-table>
      </div>
      <div class="col-12">
        <q-table
          @row-click="postProjectQuantityItemEvt"
          title="Items"
          :rows="items"
          :columns="itemColumns"
          row-key="description"
          dense
        >
          <template v-slot:top-right>
            <q-input borderless dense debounce="300" v-model="itemfilter" outlined placeholder="Search">
              <template v-slot:append>
                <q-icon name="search" />
              </template>
            </q-input>
          </template>
        </q-table>
      </div>
    </div>
    <q-dialog
      v-model="projectItemDialog"
    >
      <q-card style="width: 800px; max-width: 80vw;">
        <div class="q-pa-md">
          <div class="text-h6">{{ projectItem.quantityItem.description }}</div>
          <q-form
            class="q-pa-md"
            @submit="putProjectQuantityItem()"
          >
            <div class="row q-col-gutter-x-md q-col-gutter-y-md">
              <div class="col-4">
                <q-input
                  v-model="projectItem.itemId"
                  :dense="true"
                  label="Item Id"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-input
                  v-model="projectItem.planQuantity"
                  :dense="true"
                  label="Plan Quantity"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-input
                  v-model="projectItem.unitPrice"
                  :dense="true"
                  label="Unit Price"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-select
                  label="Unit Type"
                  emit-value
                  map-options
                  outlined
                  :dense="true"
                  v-model="projectItem.unitTypeId"
                  :options="quantityUnitTypes"
                  option-value="id"
                  option-label="unit"
                />
              </div>
            </div>
            <q-separator spaced />
            <div>
              <q-btn label="Submit" type="submit" color="primary"/>
              <q-btn label="Delete" type="reset" color="red" flat class="q-ml-sm" v-touch-hold:3000.mouse="deletProjectItem">
                <q-tooltip
                  transition-show="flip-right"
                  transition-hide="flip-left"
                >
                  Hold for 3 sec.
                </q-tooltip>
              </q-btn>
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import GenconService from '../../services/genconService'
import notifyService from '../../services/notifyService'

export default {
    mixins: [
    ],
    data() {
        return {
          projectId: parseInt(this.$route.params.id),
          projectItemDialog: false,
          submitDisable: false,
          itemfilter: '',
          projectItemfilter: '',
          projectItems: [],
          projectItem: {},
          quantityUnitTypes: [],
          items: [],
          itemColumns: [
            { name: 'description', label: 'Name', align: 'left', field: 'description' },
          ],
          projectItemColumns: [
            { name: 'itemId', align: 'left', label: 'Item Id', field: row => row.itemId },
            { name: 'quantityItem.description', align: 'left', label: 'Item', field: row => row.quantityItem.description },
            { name: 'planQuantity', label: 'Plan Quantity', field: 'planQuantity'},
            { name: 'unitPrice', label: 'Unit Price', field: 'unitPrice' },
            { name: 'unitType.unit', align: 'center', label: 'Unit', field: row => row.unitType.unit },
          ],
        }
    },
    created() {
      this.getItems()
      this.getProjectItems()
      GenconService.getQuantityUnitTypes()
        .then(res => {
          this.quantityUnitTypes = res.data
        })
    },
    methods: {
      getItems() {
        GenconService.getQuantityItems().then(res => {
          this.items = res.data
        })
      },
      getProjectItems() {
        GenconService.getQuantityProjectItems(this.projectId).then(res => {
          this.projectItems = res.data
        })
      },
      async postProjectQuantityItemEvt(evt, row) {
        await GenconService.postProjectQuantityItem({ quantityItemId: row.id, projectId: this.projectId })
        .catch(error => {
          notifyService.notifyError(error)
        })
        this.getProjectItems()
      },
      async postProjectQuantityItem() {
        await GenconService.postProjectQuantityItem(this.projectItem)
        this.getProjectItems()
      },
      async putProjectQuantityItem() {
        await GenconService.putProjectQuantityItem(this.projectItem)
        notifyService.notifySuccess('Entry updated')
        this.getProjectItems()
      },
      async showprojectItemDialogEvt(evt, row) {
        await GenconService.getQuantityProjectItem(row.id)
        .then(res => {
          this.projectItem = res.data
        })
        this.projectItemDialog = true
      },
      async deletProjectItem() {
        await GenconService.deleteQuantityProjectItem(this.projectItem.id)
        .then(function() {
          notifyService.notifySuccess("Item deleted")
        })
        .catch(error => {
          notifyService.notifyError(error)
        })
        this.projectItemDialog = false
        this.getProjectItems()
      },
    },
}
</script>
