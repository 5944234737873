/* eslint-disable */
import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    getModelState (model, property) {
      if (model[property] === undefined) {
        return false
      } else {
        return true
      }
    },
    getModelError (model, property) {
      if (model[property] === undefined) {
        return ''
      } else {
        return model[property][0]
      }
    }
  }
})
