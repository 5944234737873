<template>
  <div>
     <div class="row q-col-gutter-x-md q-col-gutter-y-md">
        <div class="col-12">
            <q-table
              @row-click="showLapTestDialogEv"
              :dense="true"
              title="Low Air Pressure Tests"
              :rows="lapTests"
              :columns="columnsLapTests"
              :pagination="{ rowsPerPage: 15 }"
              :filter="filter"
            >
            <template v-slot:top-right>
              <q-input borderless dense debounce="300" v-model="filter" outlined placeholder="Search">
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
              <div class="q-pl-md">
                <q-btn-dropdown color="primary" label="Actions">
                  <q-list>
                    <q-item clickable v-close-popup @click="showLapTestDialog(0)">
                      <q-item-section>
                        <q-item-label>Add</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup @click="Export()">
                      <q-item-section>
                        <q-item-label>Export</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-btn-dropdown>
              </div>
            </template>
            </q-table>
        </div>
    </div>
    <q-dialog
      v-model="lapTestDialog"
    >
      <q-card style="width: 50%;">
        <q-card-section>
        <div class="text-h6">Low Air Pressure Test</div>
        </q-card-section>
        <div>
          <q-form
            @submit="postLapTest()"
            class="q-pa-md"
          >
            <div class="row q-col-gutter-x-md q-col-gutter-y-md">
              <div class="col-4">
                <q-input
                  v-model="lapTest.dateDisplay"
                  dense
                  outlined
                  type="date"
                />
              </div>
              <div class="col-8"></div>
              <div class="col-12">
                <q-input
                  v-model="lapTest.location"
                  dense
                  outlined
                  label="Location"
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="lapTest.length"
                  dense
                  outlined
                  label="Pipe Length"
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="lapTest.testTime"
                  dense
                  outlined
                  label="Test Time"
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="lapTest.beginTime"
                  dense
                  outlined
                  label="Begin Time"
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="lapTest.endTime"
                  dense
                  outlined
                  label="End Time"
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="lapTest.initialPSI"
                  dense
                  outlined
                  label="Initial PSI"
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="lapTest.finalPSI"
                  dense
                  outlined
                  label="Final PSI"
                />
              </div>
              <div class="col-4">
                <q-select label="Passed" outlined emit-value map-options :dense="true" v-model="lapTest.passed" :options="passed" />
              </div>
              <div class="col-12">
                <q-input
                  v-model="lapTest.notes"
                  :dense="true"
                  label="Notes"
                  outlined
                  type="textarea"
                />
              </div>
            </div>
            <q-separator spaced />
            <div>
              <q-btn label="Submit" type="submit" :disable="submitDisable" :loading="submitDisable" color="primary"/>
              <q-btn label="Delete" type="reset" color="red" flat class="q-ml-sm" :disable="submitDisable" :loading="submitDisable" v-touch-hold:3000.mouse="deleteLapTest">
                <q-tooltip
                  transition-show="flip-right"
                  transition-hide="flip-left"
                >
                  Hold for 3 sec.
                </q-tooltip>
              </q-btn>
            </div>
            <q-separator spaced />
            <user-card :userId="lapTest.applicationUserId"></user-card>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import genconService from "../../services/genconService"
import notifyService from "../../services/notifyService"
import appService from "../../services/appService"
import UserCard from "./UserCard.vue"

export default {
    mixins: [],
    data() {
        return {
            filter: "",
            projectId: this.$route.params.id,
            passed: [
                { value: true, label: "Yes" },
                { value: false, label: "No" }
            ],
            lapTest: {},
            lapTests: [],
            lapTestDialog: false,
            submitDisable: false,
            columnsLapTests: [
                { name: "dateDisplay", align: "left", label: "Date", field: "dateDisplay" },
                { name: "location", align: "left", label: "Location", field: "location" },
                { name: "length", align: "center", label: "Length", field: "length" },
                { name: "testTime", align: "center", label: "TestTime", field: "testTime" },
                { name: "beginTime", align: "center", label: "Begin Time", field: "beginTime" },
                { name: "endTime", align: "center", label: "End Time", field: "endTime" },
                { name: "initialPSI", align: "center", label: "Initial PSI", field: "initialPSI" },
                { name: "finalPSI", align: "center", label: "Final PSI", field: "finalPSI" },
                { name: "passed", align: "center", label: "Passed", field: "passed" },
            ],
        };
    },
    created() {
        this.getLapTests(this.projectId);
    },
    methods: {
        async getLapTest(id) {
            await genconService.getLapTest(id)
                .then(res => {
                this.lapTest = res.data;
            });
        },
        async getLapTests(projectId) {
            await genconService.getLapTests(projectId)
                .then(res => {
                this.lapTests = res.data;
            });
        },
        async postLapTest() {
            this.lapTest.projectId = this.projectId;
            await genconService.postLapTest(this.lapTest)
                .then(res => {
                this.lapTest = res.data;
                notifyService.notifySuccess("Work saved");
            })
                .catch(error => {
                notifyService.notifyError(error);
            });
            await this.getLapTests(this.projectId);
        },
        async deleteLapTest() {
            await genconService.deleteLapTest(this.lapTest.id)
                .then(function () {
                notifyService.notifySuccess("Entry deleted");
            })
                .catch(error => {
                notifyService.notifyError(error);
            });
            this.lapTestDialog = false;
            await this.getLapTests(this.projectId);
        },
        async showLapTestDialogEv(evt, row) {
            await this.getLapTest(row.id);
            this.lapTestDialog = true;
        },
        async showLapTestDialog() {
            this.lapTest = {};
            this.lapTestDialog = true;
        },
        async Export() {
            appService.download(`/LowAirPressureTest/Export/ProjectId/${this.projectId}`);
        }
    },
    components: { UserCard }
}
</script>
