<template>
  <div>
     <div class="row q-col-gutter-x-md q-col-gutter-y-md">
        <div class="col-12">
            <q-table
              @row-click="showVacTestDialogEv"
              :dense="true"
              title="Vaccum Test"
              :rows="vaccumTests"
              :columns="columnsVaccumTests"
              :pagination="{ rowsPerPage: 15 }"
              :filter="filter"
            >
            <template v-slot:top-right>
              <q-input borderless dense debounce="300" v-model="filter" outlined placeholder="Search">
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
              <div class="q-pl-md">
                <q-btn-dropdown color="primary" label="Actions">
                  <q-list>
                    <q-item clickable v-close-popup @click="showVacTestDialog(0)">
                      <q-item-section>
                        <q-item-label>Add</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup @click="Export()">
                      <q-item-section>
                        <q-item-label>Export</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-btn-dropdown>
              </div>
            </template>
            </q-table>
        </div>
    </div>
    <q-dialog
      v-model="vacTestDialog"
    >
      <q-card style="width: 50%;">
        <q-card-section>
        <div class="text-h6">Vaccum Test</div>
        </q-card-section>
        <div>
          <q-form
            @submit="postVaccumTest()"
            class="q-pa-md"
          >
            <div class="row q-col-gutter-x-md q-col-gutter-y-md">
              <div class="col-4">
                <q-input
                  v-model="vaccumTest.dateDisplay"
                  dense
                  outlined
                  type="date"
                />
              </div>
              <div class="col-6" />
              <div class="col-12">
                <q-input
                  v-model="vaccumTest.location"
                  :dense="true"
                  label="Location"
                  outlined
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="vaccumTest.startPressure"
                  :dense="true"
                  label="Start Hg"
                  outlined
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="vaccumTest.endPressure"
                  :dense="true"
                  label="End Hg"
                  outlined
                />
              </div>
              <div class="col-8">
                <q-input
                  v-model="vaccumTest.testTime"
                  :dense="true"
                  label="Test Time"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-select label="Passed" outlined emit-value map-options :dense="true" v-model="vaccumTest.passed" :options="passed" />
              </div>
              <div class="col-12">
                <q-input
                  v-model="vaccumTest.notes"
                  :dense="true"
                  label="Notes"
                  outlined
                  type="textarea"
                />
              </div>
            </div>
            <q-separator spaced />
            <div>
              <q-btn label="Submit" type="submit" :disable="submitDisable" :loading="submitDisable" color="primary"/>
              <q-btn label="Delete" type="reset" color="red" flat class="q-ml-sm" :disable="submitDisable" :loading="submitDisable" v-touch-hold:3000.mouse="deleteVaccumTest">
                <q-tooltip
                  transition-show="flip-right"
                  transition-hide="flip-left"
                >
                  Hold for 3 sec.
                </q-tooltip>
              </q-btn>
            </div>
            <q-separator spaced />
            <user-card :userId="vaccumTest.applicationUserId"></user-card>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import genconService from "../../services/genconService"
import notifyService from "../../services/notifyService"
import appService from "../../services/appService"
import UserCard from "./UserCard.vue"

export default {
    mixins: [],
    data() {
        return {
            filter: "",
            projectId: this.$route.params.id,
            passed: [
                { value: true, label: "Yes" },
                { value: false, label: "No" }
            ],
            vaccumTest: {},
            vaccumTests: [],
            vacTestDialog: false,
            submitDisable: false,
            columnsVaccumTests: [
                { name: "dateDisplay", align: "left", label: "Date", field: "dateDisplay" },
                { name: "location", align: "center", label: "Location", field: "location" },
                { name: "startPressure", align: "center", label: "Start Hg", field: "startPressure" },
                { name: "endPressure", align: "center", label: "End Hg", field: "endPressure" },
                { name: "testTime", align: "center", label: "Test Time", field: "testTime" },
                { name: "passed", align: "center", label: "Passed", field: "passed" },
            ],
        };
    },
    created() {
        this.getVaccumTests(this.projectId);
    },
    methods: {
        async getVaccumTest(id) {
          await genconService.getVaccumTest(id)
            .then(res => {
              this.vaccumTest = res.data;
            });
        },
        async getVaccumTests(projectId) {
            await genconService.getVaccumTests(projectId)
                .then(res => {
                this.vaccumTests = res.data;
            });
        },
        async postVaccumTest() {
            this.vaccumTest.projectId = this.projectId;
            await genconService.postVaccumTest(this.vaccumTest)
                .then(res => {
                this.vaccumTest = res.data;
                notifyService.notifySuccess("Work saved");
            })
                .catch(error => {
                notifyService.notifyError(error);
            });
            await this.getVaccumTests(this.projectId);
        },
        async deleteVaccumTest() {
            await genconService.deleteVaccumTest(this.vaccumTest.id)
                .then(function () {
                notifyService.notifySuccess("Entry deleted");
            })
                .catch(error => {
                notifyService.notifyError(error);
            });
            await this.getVaccumTests(this.projectId);
        },
        async showVacTestDialogEv(evt, row) {
            await this.getVaccumTest(row.id);
            this.vacTestDialog = true;
        },
        async showVacTestDialog() {
            this.vaccumTest = {};
            this.vacTestDialog = true;
        },
        async Export() {
            appService.download(`/VaccumTests/Export/ProjectId/${this.projectId}`);
        }
    },
    components: { UserCard }
}
</script>
