<template>
    <q-card v-if="userId !== undefined">
        <q-card-section>
            <div class="text-h8">Name: {{ user.name }}</div>
            <div class="text-h8">Email: {{ user.email }}</div>
        </q-card-section>
    </q-card>
</template>

<script>
import { defineComponent } from 'vue';
import authService from '../../services/authService';

export default defineComponent({
    props: ['userId'],
    data () {
        return {
            user: { id: '', name: '', email: '' }
        }
    },
    created () {
        this.getUser()
    },
    methods: {
        async getUser () {
            await authService.getUser(this.userId)
                .then(res => {
                    this.user = res.data
                })
        }
    }
})


</script>