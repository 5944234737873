<template>
    <div class="q-pa-md">
        <q-card class="my-card">
          <q-card-section>
              <div class="text-h6">{{ project.number }} - {{ project.name }}</div>
              <div class="text-subtitle2">{{ project.location }}</div>
          </q-card-section>
          <q-separator />
          <q-tabs
            v-model="tab"
            dense
            align="left"
            class="bg-primary text-white shadow-2"
            :breakpoint="0"
          >
            <q-tab name="reports" label="Reports"/>
            <q-tab name="itemsusage" label="CPM Usage"/>
            <q-tab name="itemssetup" label="CPM Setup"/>
            <q-tab name="nuketests" label="NG Tests"/>
            <q-tab name="vactests" label="Vac Tests"/>
            <q-tab name="laptests" label="Lap Tests"/>
          </q-tabs>
        </q-card>
        <div class="q-mt-md">
          <Reports v-if="tab === 'reports'"/>
          <NukeTests v-if="tab === 'nuketests'"/>
          <VaccumTests v-if="tab === 'vactests'"/>
          <LapTest v-if="tab === 'laptests'"/>
          <ItemsSetup v-if="tab === 'itemssetup'"/>
          <ItemsUsage v-if="tab === 'itemsusage'"/>
        </div>
    </div>
</template>

<script>
import axios from '../axios'
import ItemsSetup from '../components/gencon/ItemsSetup.vue'
import ItemsUsage from '../components/gencon/ItemsUsage.vue'
import VaccumTests from '../components/gencon/VaccumTests.vue'
import LapTest from '../components/gencon/LapTest.vue'
import NukeTests from '../components/gencon/NukeTests.vue'
import Reports from "@/components/gencon/Reports";

export default {
  components: {
    Reports,
    ItemsSetup,
    ItemsUsage,
    VaccumTests,
    LapTest,
    NukeTests,
  },
  mixins: [
  ],
  data() {
    return {
      projects: [],
      project: {},
      projectId: this.$route.params.id,
      tab: 'reports',
    }
  },
  created() {
    axios.get(`/Projects/Id/${this.projectId}`)
      .then(response => {
        this.project = response.data
      })
  },
  methods: {
  }
}
</script>