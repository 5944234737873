import fileDownload from 'js-file-download'
import notifyService from "./notifyService"
import axios from '../axios';

export default {
    convertDataToImage(response) {
      let image = btoa(
      new Uint8Array(response.data)
        .reduce((data, byte) => data + String.fromCharCode(byte), '')
      );
      return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
    },
    async download(url) {
        await axios
          .get(url, {
            responseType: 'blob',
          })
          .then(res => {
            const strData = res.headers['content-disposition']
            const indexStart = strData.indexOf('filename', 0)
            const indexEnd = strData.indexOf(';', indexStart)
            const filestr = strData.substring(indexStart, indexEnd)
            var filename = filestr.split('=')[1]
            const newName = filename.replaceAll('"','')
            fileDownload(res.data, newName)
          })
          .catch(error => {
            notifyService.notifyError(error)
          })
    },
}
