<template>
  <div>
     <div class="row q-col-gutter-x-md q-col-gutter-y-md">
        <div class="col-12">
          <q-table
            title="Project Items"
            :dense="true"
            @row-click="getQuantityProjectItemEvt"
            :rows="quantityProjectItems"
            :columns="quantityProjectItemColumns"
          >
            <template v-slot:top-right>
              <q-input borderless dense debounce="300" v-model="itemfilter" outlined placeholder="Search">
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
              <div class="q-pl-md">
                <q-btn-dropdown color="primary" label="Actions">
                  <q-list>
                    <q-item clickable v-close-popup @click="ExportProjectItems">
                      <q-item-section>
                        <q-item-label>Export</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup @click="quantitiesExportDialog = true">
                      <q-item-section>
                        <q-item-label>Export to Pdf</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-btn-dropdown>
              </div>
            </template>
          </q-table>
        </div>
        <div v-if="quantityProjectItem.quantityItem != null" class="col-12">
            <q-table
              @row-click="getQuantityProjectItemUsageEvt"
              :dense="true"
              :title="quantityProjectItem.quantityItem.description"
              :rows="quantityProjectItemUsages"
              :columns="quantityProjectItemUsageColumns"
            >
            <template v-slot:top-right>
              <q-input borderless dense debounce="300" v-model="itemUsageFilter" outlined placeholder="Search">
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
              <div class="q-pl-md">
                <q-btn-dropdown color="primary" label="Actions">
                  <q-list>
                    <q-item clickable v-close-popup @click="showQuantityItemUsageDialog">
                      <q-item-section>
                        <q-item-label>Add</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup @click="ExportUsages">
                      <q-item-section>
                        <q-item-label>Export</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-btn-dropdown>
              </div>
            </template>
            </q-table>
        </div>
    </div>
    <q-dialog
      v-model="itemUsageDialog"
    >
      <q-card style="width: 50%; max-width: 80vw;">
        <div class="q-pa-md">
          <q-form
            @submit="postQuantityItemUsage()"
            class="q-pa-md"
          >
            <div class="row q-col-gutter-x-md q-col-gutter-y-md">
              <div class="col-4">
                <q-input
                  v-model="quantityProjectItemUsage.dateDisplay" 
                  dense
                  type="date"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-input
                  v-model="quantityProjectItemUsage.location"
                  :dense="true"
                  label="Location"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-input
                  v-model="quantityProjectItemUsage.quantity"
                  :dense="true"
                  label="Quantity"
                  outlined
                />
              </div>
              <div class="col-12">
                <q-input
                  v-model="quantityProjectItemUsage.remarks"
                  :dense="true"
                  label="Remarks"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-select
                  label="Type" 
                  outlined 
                  emit-value 
                  map-options 
                  :dense="true" 
                  v-model="quantityProjectItemUsage.quantityItemUsageTypeId" 
                  :options="quantityUsageTypes"
                  option-value="id"
                  option-label="name"
                  />
              </div>
            </div>
            <q-separator spaced />
            <div>
              <q-btn label="Submit" type="submit" :disable="submitDisable" :loading="submitDisable" color="primary"/>
              <q-btn label="Delete" type="reset" color="red" flat class="q-ml-sm" v-touch-hold:3000.mouse="deleteQuantityItemUsage">
                <q-tooltip
                  transition-show="flip-right"
                  transition-hide="flip-left"
                >
                  Hold for 3 sec.
                </q-tooltip>
              </q-btn>
            </div>
          </q-form>
          <q-separator spaced />
          <div>
            <user-card :userId="quantityProjectItemUsage.inspectorId"></user-card>
          </div>
        </div>
      </q-card>
    </q-dialog>
    <q-dialog
        v-model="quantitiesExportDialog"
    >
      <q-card style="width: 20%; max-width: 20vw;">
        <div class="q-pa-md">
          <q-form
              @submit="ExportPdf()"
              class="q-pa-md"
          >
            <div class="row q-col-gutter-x-md q-col-gutter-y-md">
              <div class="col-12">
                <q-input
                    v-model="quantitiesExport.startDate"
                    :dense="true"
                    label="Start Date"
                    outlined
                />
              </div>
              <div class="col-12">
                <q-input
                    v-model="quantitiesExport.endDate"
                    :dense="true"
                    label="End Date"
                    outlined
                />
              </div>
            </div>
            <q-separator spaced />
            <div>
              <q-btn label="Submit" type="submit" color="primary"/>
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import genconService from "../../services/genconService"
import notifyService from "../../services/notifyService"
import appService from "../../services/appService"
import UserCard from "./UserCard.vue"

export default {
    data() {
        return {
            projectId: this.$route.params.id,
            itemUsageDialog: false,
            quantitiesExportDialog: false,
            quantitiesExport: { projectId: 0, startDate: "", endDate: "" },
            submitDisable: false,
            itemfilter: "",
            itemUsageFilter: "",
            quantityUsageTypes: [],
            quantityProjectItem: {},
            quantityProjectItems: [],
            quantityProjectItemColumns: [
                { name: "itemId", align: "left", label: "Item Id", field: row => row.itemId },
                { name: "quantityItem.description", align: "left", label: "Name", field: row => row.quantityItem.description },
                { name: "planQuantity", label: "Plan Qty.", field: "planQuantity" },
                { name: "unitPrice", label: "Unit Price", field: "unitPrice" },
                { name: "unitType.unit", label: "Unit", field: row => row.unitType.unit },
                { name: "quantityUsed", label: "Used Qty.", field: "quantityUsed" },
                { name: "percentCompleteDisplay", label: "% Complete", field: "percentCompleteDisplay" },
                { name: "percentofCostDisplay", label: "% Cost", field: "percentofCostDisplay" },
                { name: "costDisplay", label: "Cost", field: "costDisplay" },
            ],
            quantityProjectItemUsage: {},
            quantityProjectItemUsages: [],
            quantityProjectItemUsageColumns: [
                { name: "dateDisplay", align: "left", label: "Date", field: "dateDisplay" },
                { name: "location", label: "Location", field: "location" },
                { name: "remarks", label: "Remarks", field: "remarks" },
                { name: "quantity", label: "Quantity", field: "quantity" },
                { name: "quantityItemUsageType.name", label: "M/P/E", field: row => row.quantityItemUsageType.name },
            ],
        };
    },
    created() {
        this.getQuantityProjectItems(this.projectId);
        this.getQuantityUsageTypes();
    },
    methods: {
        async getQuantityProjectItems(projectId) {
            genconService.getQuantityProjectItems(projectId)
                .then(res => {
                this.quantityProjectItems = res.data;
            });
        },
        async getQuantityProjectItemUsages(projectItemId) {
            await genconService.getQuantityItemUsages(projectItemId)
                .then(res => {
                this.quantityProjectItemUsages = res.data;
            });
        },
        async getQuantityProjectItemEvt(evt, row) {
            await genconService.getQuantityProjectItem(row.id)
                .then(res => {
                this.quantityProjectItem = res.data;
            });
            this.getQuantityProjectItemUsages(row.id);
        },
        async getQuantityProjectItemUsageEvt(evt, row) {
            await genconService.getQuantityItemUsage(row.id)
                .then(res => {
                this.quantityProjectItemUsage = res.data;
            });
            this.itemUsageDialog = true;
        },
        async showQuantityItemUsageDialog() {
            this.quantityProjectItemUsage = {};
            this.itemUsageDialog = true;
        },
        async getQuantityUsageTypes() {
            genconService.getQuantityUsageTypes()
                .then(res => {
                this.quantityUsageTypes = res.data;
            });
        },
        async postQuantityItemUsage() {
            this.quantityProjectItemUsage.quantityItemProjectId = this.quantityProjectItem.id;
            await genconService.postQuantityProjectItemUsage(this.quantityProjectItemUsage)
                .then(res => {
                notifyService.notifySuccess("Work saved");
                this.quantityProjectItemUsage = res.data;
            })
                .catch(error => {
                notifyService.notifyError(error);
            });
            await this.getQuantityProjectItemUsages(this.quantityProjectItem.id);
            await this.getQuantityProjectItems(this.projectId);
        },
        async deleteQuantityItemUsage() {
            await genconService.deleteQuantityItemUsage(this.quantityProjectItemUsage.id)
                .then(function () {
                notifyService.notifySuccess("Entry deleted");
            })
                .catch(error => {
                notifyService.notifyError(error);
            });
            await this.getQuantityProjectItemUsages(this.quantityProjectItem.id);
            await this.getQuantityProjectItems(this.projectId);
            this.itemUsageDialog = false;
        },
        async ExportProjectItems() {
            await appService.download(`/QuantityProjectItems/Export/ProjectId/${this.projectId}`);
        },
        async ExportUsages() {
            await appService.download(`/QuantityItemsUsage/Export/QuantityProjectItemId/${this.quantityProjectItem.id}`);
        },
        async ExportPdf() {
            await appService.download(`/QuantityProjectItems/ExportPDF/ProjectId/${this.projectId}/StartDate/${new Date(this.quantitiesExport.startDate).toJSON()}/EndDate/${new Date(this.quantitiesExport.endDate).toJSON()}`);
        }
    },
    components: { UserCard }
}
</script>
