<template>
  <div>
    <div class="row q-col-gutter-x-md q-col-gutter-y-md">
      <div class="col-12">
          <q-table
            @row-click="showReportDialogEv"
            :dense="true"
            title="Reports"
            :rows="reports"
            :columns="columnsReports"
            :pagination="{ rowsPerPage: 15 }"
            :filter="filter"
          >
          <template v-slot:top-right>
            <q-input borderless dense debounce="300" v-model="filter" outlined placeholder="Search">
              <template v-slot:append>
                <q-icon name="search" />
              </template>
            </q-input>
            <div class="q-pl-md">
              <q-btn-dropdown color="primary" label="Actions">
                <q-list>
                  <q-item clickable v-close-popup @click="showReportDialog(0)">
                    <q-item-section>
                      <q-item-label>Add</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="weeklyExportDialog = true">
                    <q-item-section>
                      <q-item-label>Export Weekly</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
            </div>
          </template>
        </q-table>
      </div>
    </div>
    <q-dialog
      v-model="reportDialog"
    >
      <q-card style="width: 700px; max-width: 80vw;">
        <q-card-section>
        <div class="text-h6">Report</div>
        </q-card-section>
        <div>
          <q-form
            id="myForm"
            @submit="postReport()"
            class="q-pa-md"
          >
            <div class="row q-col-gutter-x-md q-col-gutter-y-md">
              <div class="col-4">
                <q-input
                  v-model="report.date"
                  dense
                  outlined
                  mask="##/##/####"
                  hint="MM/DD/YYYY"
                />
              </div>
              <div class="col-6" />
              <div class="col-12">
                <q-input
                  v-model="report.weather"
                  dense
                  outlined
                  label="Weather"
                />
              </div>
              <div class="col-12">
                <q-input
                  v-model="report.notes"
                  dense
                  outlined
                  label="Notes"
                  type="textarea"
                />
              </div>
              <div class="col-12">
                <q-file outlined multiple v-model="report.files" label="Images" />
              </div>
              <div class="col-12">
                <q-list bordered separator dense v-if="reportImages !== null">
                  <q-item clickable v-for="image in reportImages" :key="image.id">
                    <q-item-section @click="showImage(image)">
                      {{ image.fileName }}
                    </q-item-section>
                    <q-item-section side>
                      <q-btn text-color="white" color="red" label="Delete" @click="deletePhoto(image)">
                        <q-tooltip
                            transition-show="flip-right"
                            transition-hide="flip-left"
                        >
                          Hold for 3 sec.
                        </q-tooltip>
                      </q-btn>
                    </q-item-section>
                  </q-item>
                </q-list>
              </div>
            </div>
            <q-separator spaced />
            <div>
              <q-btn label="Submit" type="submit" :disable="submitDisable" :loading="submitDisable" color="primary"/>
              <q-btn v-if="report.id !== 0" label="Export" color="primary" class="q-ml-sm" @click="downloadReport()"/>
              <q-btn v-if="report.id !== 0" label="Delete" type="reset" color="red" flat class="q-ml-sm" :disable="submitDisable" :loading="submitDisable" v-touch-hold:3000.mouse="deleteReport">
                <q-tooltip
                  transition-show="flip-right"
                  transition-hide="flip-left"
                >
                  Hold for 3 sec.
                </q-tooltip>
              </q-btn>
            </div>
            <q-separator spaced />
            <div>
              <user-card :userId="report.applicationUserId"></user-card>
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
    <q-dialog v-model="pictureDialog" transition-show="scale" transition-hide="scale">
      <q-card class="" style="width: 500px">
        <q-card-section class="q-pt-none">
        </q-card-section>
        <q-card-section class="q-pt-none">
          <q-img
            :src="image"
            spinner-color="red"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="weeklyExportDialog" transition-show="scale" transition-hide="scale">
      <q-card class="" style="width: 300px">
        <q-card-section>
        <div class="text-h6">Export Weekly Report</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <div class="col-4">
            <q-input
              v-model="weeklyDate"
              dense
              outlined
              mask="##/##/####"
              hint="MM/DD/YYYY - Enter the Friday Date"
              class="q-ma-sm"
            />
          </div>
          <q-btn label="Export" color="primary" @click="downloadWeeklyReport()"/>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import genconService from "../../services/genconService"
import notifyService from "../../services/notifyService"
import appService from "../../services/appService"
import axios from "../../axios"
import swalService from "@/services/swalService"
import { useQuasar } from 'quasar'
import UserCard from "./UserCard.vue"

export default {
    mixins: [],
    data() {
        return {
            q: useQuasar(),
            filter: "",
            weeklyDate: "",
            weeklyExportDialog: false,
            projectId: this.$route.params.id,
            image: "",
            imageName: "",
            report: {},
            reports: [],
            reportImages: [],
            reportDialog: false,
            pictureDialog: false,
            submitDisable: false,
            columnsReports: [
                { name: "date", align: "left", label: "Date", field: "date", format: (value) => `${new Intl.DateTimeFormat("en-US").format(new Date(value))}` },
            ],
        };
    },
    created() {
        this.getReports(this.projectId);
    },
    methods: {
        async getReport(id) {
            await genconService.getReport(id)
                .then(res => {
                this.report = res.data;
                this.report.date = new Intl.DateTimeFormat("en-US", { month: "2-digit", day: "2-digit", year: "numeric" }).format(new Date(res.data.date));
                this.report.files = [];
                console.log(this.report.date);
            });
            await this.getReportImages(id);
        },
        async getReports(projectId) {
            await genconService.getReports(projectId)
                .then(res => {
                this.reports = res.data;
            });
        },
        async getReportPhoto(reportImageId) {
            await axios.get(`/Reports/Images/ReportImageId/${reportImageId}`, { responseType: "arraybuffer" })
                .then((response) => {
                this.image = appService.convertDataToImage(response);
            });
        },
        async postReport() {
            this.submitDisable = true;
            this.report.projectId = this.projectId;
            let data = new FormData();
            data.set("id", this.report.id);
            data.set("projectId", this.report.projectId);
            data.set("date", new Date(this.report.date).toJSON());
            data.set("weather", this.report.weather);
            data.set("notes", this.report.notes);
            for (let i = 0; i < this.report.files.length; i++) {
                data.append("files", this.report.files[i]);
            }
            await genconService.postReport(data)
                .then(res => {
                this.report = res.data;
                notifyService.notifySuccess("Work saved");
            })
                .catch(error => {
                notifyService.notifyError(error);
            });
            this.submitDisable = false;
            await this.getReports(this.projectId);
            await this.getReportImages(this.report.id);
        },
        async deleteReport() {
            await genconService.deleteReport(this.report.id)
                .then(function () {
                notifyService.notifySuccess("Entry deleted");
            })
                .catch(error => {
                notifyService.notifyError(error);
            });
            this.reportDialog = false;
            await this.getReports(this.projectId);
        },
        async showReportDialogEv(evt, row) {
            await this.getReport(row.id);
            this.reportDialog = true;
        },
        async showReportDialog() {
            this.report = { id: 0, files: [] };
            this.reportImages = [];
            this.reportDialog = true;
        },
        async showImage(reportImage) {
            await this.getReportPhoto(reportImage.id);
            this.imageName = reportImage.fileName;
            this.pictureDialog = true;
        },
        async deletePhoto(reportimage) {
            await swalService.confirmDelete()
                .then(async (response) => {
                if (response.isConfirmed) {
                    await genconService.deleteReportPhoto(reportimage.id).then(function () {
                        notifyService.notifySuccess("Photo deleted");
                    });
                }
            });
            await this.getReport(this.report.id);
            await this.getReportImages(this.report.id);
        },
        async getReportImages(reportId) {
            await genconService.getReportImages(reportId)
                .then(res => {
                this.reportImages = res.data;
            });
        },
        async downloadReport() {
            await appService.download(`/Reports/Export/ReportId/${this.report.id}`);
        },
        async downloadWeeklyReport() {
            appService.download(`/Reports/Export/Weekly/ProjectId/${this.projectId}/Date/${new Date(this.weeklyDate).toJSON()}`);
        }
    },
    components: { UserCard }
}
</script>
