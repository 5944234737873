<template>
  <div>
     <div class="row q-col-gutter-x-md q-col-gutter-y-md">
        <div class="col-12">
            <q-table
              @row-click="showNukeGaugeTestDialogEvt"
              :dense="true"
              title="Nuke Gauge Tests"
              :rows="nukeGaugeTests"
              :columns="nukeGaugeTestColumns"
              :pagination="{ rowsPerPage: 15 }"
              :filter="filter"
            >
            <template v-slot:top-right>
            <q-input borderless dense debounce="300" v-model="filter" outlined placeholder="Search">
              <template v-slot:append>
                <q-icon name="search" />
              </template>
            </q-input>
            <div class="q-pl-md">
              <q-btn-dropdown color="primary" label="Actions">
                <q-list>
                  <q-item clickable v-close-popup @click="showNukeGaugeTestDialog()">
                    <q-item-section>
                      <q-item-label>Add</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="Export()">
                    <q-item-section>
                      <q-item-label>Export</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
            </div>
          </template>
            </q-table>
        </div>
    </div>
    <q-dialog
      v-model="nukeGaugeTestDialog"
    >
      <q-card style="width: 50%;">
        <q-card-section>
        <div class="text-h6">Nuke Gauge Test</div>
        </q-card-section>
        <div>
          <q-form
            @submit="postNukeGaugeTest()"
            class="q-pa-md"
          >
            <div class="row q-col-gutter-x-md q-col-gutter-y-md">
              <div class="col-4">
                <q-input
                  v-model="nukeGaugeTest.dateDisplay"
                  dense
                  outlined
                  type="date"
                  :error-message="getModelError(nukeGaugeTestModelState, 'DateDisplay')"
                  :error="getModelState(nukeGaugeTestModelState, 'DateDisplay')"
                />
              </div>
              <div class="col-6" />
              <div class="col-12">
                <q-input
                  v-model="nukeGaugeTest.location"
                  :dense="true"
                  label="Location"
                  outlined
                  :error-message="getModelError(nukeGaugeTestModelState, 'Location')"
                  :error="getModelState(nukeGaugeTestModelState, 'Location')"
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="nukeGaugeTest.soilType"
                  :dense="true"
                  label="Soil Type"
                  outlined
                  :error-message="getModelError(nukeGaugeTestModelState, 'SoilType')"
                  :error="getModelState(nukeGaugeTestModelState, 'SoilType')"
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="nukeGaugeTest.modeAndDepth"
                  :dense="true"
                  label="Mode & Depth"
                  outlined
                  :error-message="getModelError(nukeGaugeTestModelState, 'ModeAndDepth')"
                  :error="getModelState(nukeGaugeTestModelState, 'ModeAndDepth')"
                />
              </div>
              <div class="col-6">
                <q-input
                    v-model="nukeGaugeTest.offset"
                    :dense="true"
                    label="Offset"
                    outlined
                />
              </div>
              <div class="col-6">
                <q-input
                    v-model="nukeGaugeTest.elevation"
                    :dense="true"
                    label="Elevation"
                    outlined
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="nukeGaugeTest.wetDensity"
                  :dense="true"
                  label="Wet Density"
                  outlined
                  :error-message="getModelError(nukeGaugeTestModelState, 'WetDensity')"
                  :error="getModelState(nukeGaugeTestModelState, 'WetDensity')"
                />
              </div>
              <div class="col-6">
                <q-input
                    v-model="nukeGaugeTest.dryDensityDisplay"
                    :dense="true"
                    label="Dry Density"
                    outlined
                    readonly
                />
              </div>
              <div class="col-6">
                <q-input
                    v-model="nukeGaugeTest.moistureDisplay"
                    :dense="true"
                    label="Moisture"
                    outlined
                    readonly
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="nukeGaugeTest.moisturePercentage"
                  :dense="true"
                  label="Moisture %"
                  outlined
                  :error-message="getModelError(nukeGaugeTestModelState, 'MoisturePercentage')"
                  :error="getModelState(nukeGaugeTestModelState, 'MoisturePercentage')"
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="nukeGaugeTest.standardDensity"
                  :dense="true"
                  label="Standard Density"
                  outlined
                  :error-message="getModelError(nukeGaugeTestModelState, 'StandardDensity')"
                  :error="getModelState(nukeGaugeTestModelState, 'StandardDensity')"
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="nukeGaugeTest.optimumMoisture"
                  :dense="true"
                  label="Opt. Moisture"
                  outlined
                  :error-message="getModelError(nukeGaugeTestModelState, 'OptimumMoisture')"
                  :error="getModelState(nukeGaugeTestModelState, 'OptimumMoisture')"
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="nukeGaugeTest.compactionPercentageDisplay"
                  :dense="true"
                  label="Compaction Percentage"
                  outlined
                  readonly
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="nukeGaugeTest.correctionFactor"
                  :dense="true"
                  label="Correction Factor"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-select label="Passed" outlined emit-value map-options :dense="true" v-model="nukeGaugeTest.passed" :options="passed" />
              </div>
              <div class="col-12">
                <q-input
                  v-model="nukeGaugeTest.notes"
                  :dense="true"
                  label="Notes"
                  outlined
                  type="textarea"
                />
              </div>
            </div>
            <q-separator spaced />
            <div>
              <q-btn label="Submit" type="submit" :disable="submitDisable" :loading="submitDisable" color="primary"/>
              <q-btn label="Delete" type="reset" color="red" flat class="q-ml-sm" :disable="submitDisable" :loading="submitDisable" v-touch-hold:3000.mouse="deleteNukeGaugeTest">
                <q-tooltip
                  transition-show="flip-right"
                  transition-hide="flip-left"
                >
                  Hold for 3 sec.
                </q-tooltip>
              </q-btn>
            </div>
            <q-separator spaced />
            <user-card :userId="nukeGaugeTest.inspectorId"></user-card>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import genconService from "../../services/genconService"
import notifyService from "../../services/notifyService"
import appService from "../../services/appService"
import formMixin from "../../mixins/formMixin"
import UserCard from "./UserCard.vue"

export default {
    mixins: [formMixin],
    data() {
        return {
            filter: "",
            projectId: this.$route.params.id,
            passed: [
                { value: true, label: "Yes" },
                { value: false, label: "No" }
            ],
            nukeGaugeTest: {},
            nukeGaugeTests: [],
            nukeGaugeTestModelState: {},
            nukeGaugeTestDialog: false,
            submitDisable: false,
            nukeGaugeTestColumns: [
                { name: "dateDisplay", align: "left", label: "Date", field: "dateDisplay" },
                { name: "location", align: "center", label: "Location", field: "location" },
                { name: "modeAndDepth", align: "center", label: "Mode & Depth", field: "modeAndDepth" },
                { name: "wetDensity", align: "center", label: "Wet Density", field: "wetDensity" },
                { name: "dryDensityDisplay", align: "center", label: "Dry Density", field: "dryDensityDisplay" },
                { name: "moisturePercentage", align: "center", label: "% Moisture", field: "moisturePercentage" },
                { name: "standardDensity", align: "center", label: "Std. Density", field: "standardDensity" },
                { name: "moistureDisplay", align: "center", label: "Moisture", field: "moistureDisplay" },
                { name: "optimumMoisture", align: "center", label: "Opt. Moisture", field: "optimumMoisture" },
                { name: "compactionPercentageDisplay", align: "center", label: "% Compaction", field: "compactionPercentageDisplay" },
                { name: "passed", align: "center", label: "Passed", field: "passed" },
            ],
        };
    },
    created() {
        this.getNukeGaugeTests(this.projectId);
    },
    methods: {
        async getNukeGaugeTests(projectId) {
            await genconService.getNukeGaugeTests(projectId)
                .then(res => {
                this.nukeGaugeTests = res.data;
            });
        },
        async getNukeGaugeTest(id) {
            await genconService.getNukeGaugeTest(id)
                .then(res => {
                this.nukeGaugeTest = res.data;
            })
                .catch(error => {
                notifyService.notifyError(error);
            });
        },
        async postNukeGaugeTest() {
            this.nukeGaugeTestModelState = {};
            this.nukeGaugeTest.projectId = this.projectId;
            await genconService.postNukeGaugeTest(this.nukeGaugeTest)
                .then(res => {
                this.nukeGaugeTest = res.data;
                notifyService.notifySuccess("Worked saved");
            })
                .catch(error => {
                this.nukeGaugeTestModelState = error.response.data.errors;
            });
            await this.getNukeGaugeTests(this.projectId);
        },
        async deleteNukeGaugeTest() {
            this.nukeGaugeTestModelState = {};
            await genconService.deleteNukeGaugeTest(this.nukeGaugeTest.id)
                .then(function () {
                notifyService.notifySuccess("Entry deleted");
            })
                .catch(error => {
                notifyService.notifyError(error);
            });
            this.nukeGaugeTestDialog = false;
            await this.getNukeGaugeTests(this.projectId);
        },
        async showNukeGaugeTestDialog() {
            this.nukeGaugeTestModelState = {};
            this.nukeGaugeTestDialog = true;
            this.nukeGaugeTest = {};
        },
        async showNukeGaugeTestDialogEvt(evt, row) {
            await this.getNukeGaugeTest(row.id);
            this.nukeGaugeTestDialog = true;
        },
        async Export() {
            appService.download(`/NukeGaugeTests/Export/ProjectId/${this.projectId}`);
        }
    },
    components: { UserCard }
}
</script>
